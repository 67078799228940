// 教师移动
<template>
  <div class="page">
    <div class="swiper-container top-banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in banners" :key="index">
          <img :src="$baseUrl + item.cover_pc" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
    <!-- 中部 -->
    <div class="goods">
      <div class="white-title">
        <h2>好成绩，从<span>严选好老师</span>开始</h2>
        <p>了解学生问题，针对性辅导，学生提升显著</p>
      </div>
      <div class="goods-box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="goods-item">
                <div class="goodlist-img">
                  <img :src="$baseUrl + item.cover_pc" alt=""/>
                </div>
              </div>
            </div>
          </div>
            <div class="margin"></div>
          <div class="goods-host"></div>

          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <!-- <div class="button" @click="contact">好老师抢先预约</div> -->
    </div>
    <!-- 中部 -->
    <!-- 老师集合图 -->
    <div class="teachers">
      <img
        :src="$baseUrl + item.cover_pc"
        v-for="(item, index) in array"
        :key="index"
      />
    </div>
    <!-- 老师集合图 -->
    <!-- 尾部 -->
    <div class="footer">
      <img src="../assets/imgs/teachers_app/tm2.jpg" />
    </div>
  </div>
</template>
<script>
import { rotations } from "@/api/api.js";
import Swiper from "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      banners: null,
      list: null,
      array: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      rotations({ typeId: 2 }).then((res) => {
        if (res.data.code == 200) {
          this.banners = res.data.data.teachersMobile6;
          this.list = res.data.data.teachersMobile7;
          this.array = res.data.data.teachersMobile8;
          this.$nextTick(() => {
            this.init1();
            this.init2();
          });
        }
      });
    },

    // contact() {
    //   window.open(
    //     "https://tb.53kf.com/code/client/10179248/1",
    //     "_blank",
    //     "height = 600,width = 800,status = yes,toolbar = no,menubar = no,location = no,addressbar = no,top = 200,left = 600"
    //   );
    // },
    //头部轮播
    init1() {
      new Swiper(".top-banner", {
        loop: true,
        speed: 300,
        observer: true,
        grabCursor: true,
        updateOnWindowResize: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
    //中部轮播
    init2() {
      new Swiper(".goods .swiper-container", {
        loop: true,
        slidesPerView: "auto",
        autoplay: true,
        navigation: {
          nextEl: ".goods .swiper-button-next",
          prevEl: ".goods .swiper-button-prev",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/css/common.css";
.page {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
::v-deep .swiper-pagination-bullet-active {
  background: #ffffff;
}
.white-title {
  text-align: center;
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    span {
      color: #0e2d84;
    }
  }
  p {
    font-size: 14px;
    line-height: 50px;
  }
}
.goods-box {
  margin-top: 25px;
}
.swiper-button-prev {
  border: none;
  background-color: transparent;
  outline: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next {
  border: none;
  background-color: transparent;
  outline: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.button {
  width: 30%;
  text-align: center;
  border-radius: 5px;
  padding: 10px 30px;
  color: #f9eeb6;
  margin: 50px auto 50px;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(to bottom, #0b2265, #0f2f86);
}
.margin{
  margin: 20px 0;
}
// img
// {
// 	height: 100%;
// 	width: auto;
// 	left:50%;
// 	position: relative;
// 	-webkit-transform: translateX(-50%);
// 	-ms-transform: translateX(-50%);
// 	-moz-transform: translateX(-50%);
// }
// .cover
// {
// 	margin:100px auto;
// 	width: 400px;
// 	height: 400px;
// 	overflow: hidden;
// 	border: 1px solid cornflowerblue;
// 	position: relative;
// }
</style>